.div-ingredient-container{
    display: flex;
    flex-direction: column;
}

.div-ingredient-actif{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 474px;
}

.phone-div-ingredient-actif{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 350px;
}

.h2-ingredient-nomactif {
    font-family: "Poppins", sans-serif;
    color: #474748;
    font-weight: bold;
    margin: 0;
    font-size: 16px;
}

.img-ingredient-arrow{
    width: 24px;
}

.rotated {
    transform: rotate(180deg);
}

.p-ingredient-explication{
    font-family: "Poppins", sans-serif;
    width: 470px;
    color: #474748;
}

.p-ingredient-explication-phone{
    font-family: "Poppins", sans-serif;
    width: 350px;
    color: #474748;
}

.sup-ingredient-actif{
    color: #99CFE2;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    margin-left: 2px;
    font-weight: bold;
}

.div-nomIngre{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom : #E8E6E5 1px solid;

}

.div-2boutons-ingre{
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: space-around;
    
}
.p-ingredient-bouton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    border-radius: 15px;
    height: 40px;
    width: 230px;
}

.actif-ingredient{
    background-color: #99CFE2;
    color: #fff;
}

.bienfait-titre{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #99CFE2;
    font-weight: bold;
}

.bienfait-description{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #474748;
}
