body{
    margin:0;
}
.mailto, .telto{
    text-decoration: none;
}
.header-svg-mail svg {
    margin-left: 20px;
    margin-right: 5px;
    fill: rgb(255, 255, 255);
}

.header-div-container{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
/* PHONE */
.header-div-phone{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color:#111426;
}

.header-img-phone{
    width: 20px;
    height: 20px;
}

.header-p-phone{
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 12px;
}
/* MENU */
.header-div-menu{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 85px;
    width: 80%;
}

.header-p-menu{
    color: #111426;
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}

.header-img-menu{
    height: 42px;
    margin-left: 20px;
    margin-right: 20px;
}

.svg-header-menu {
    display: none;
    width: 35px;
    height: 22px;
    position: absolute;
    right: 7%;
    cursor: pointer;
}

.menu-burger{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 95vh;
}

.svg-close-menu{
    position: absolute;
    right: 15px;
    top: 100px;
    cursor: pointer;
}

.item-menu{
    font-size: 16px;
    color:#111426 ;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
}

.none{
    display: none;
}

.container-menu-link{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh
}

.header-menu-bottom{
    position: absolute;
    bottom: 0;
    background-color: #121628;
    height: 35vh;
    width: 100vw;
}

.img-menu-liposome{
    z-index: 1;
    width: 265px;
    height: auto;
    position: absolute;
    bottom: 0;
    margin-bottom: 28vh;
}

.header-menu-bottom{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.text-menu-bottom{
    color: white;
    margin: 10px;
    font-family: 'Poppins', sans-serif;
}


/* version mobile header */
@media screen and (max-width: 768px) {
    .header-p-menu{
        display:flex;
    }

    .header-img-menu{
        width: 175px;
        height: 28px;
    }

    .header-div-menu{
        height: 50px;
    }

    .header-img-phone{
        height: 13px;
        width: 13px;
        margin-bottom: 2px;
    }

    .svg-header-menu{
        display: block;
    }

    .header-p-menu{
        display: none;
    }
}

@media screen and (max-height: 650px){
    .img-menu-liposome{
        width: 180px;
        height: auto;
    }

    .container-menu-link{
        margin-top: 30px;
    }
}