.img-mi-iconMenu{
    width: 40px;
    margin-right: 30px;
}

.div-mi-menu{
    display: flex;
    width: 310px;
    justify-content: flex-start;
    cursor: pointer;
}

.div-actif{
    border-bottom: 3px solid #99CFE2;
}

.p-mi-menu{
    font-family: "Poppins", sans-serif;
    color: #474748;
    font-size: 16px;
}

.p-actif{
    color: #99CFE2;
}

.div-mi-containt{
    margin-top: 100px;
    display: flex;

}

.div-mi-content{
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1B1C3C;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    width: 550px;
    border-radius: 15px;
    box-shadow: 1px 1px 4px 0px #00000040;
    padding: 16px;
}

.div-mi-containt-phone{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 350px;
}
.div-mi-content-phone{
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1B1C3C;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    width: 350px;
    border-radius: 15px;
    box-shadow: 1px 1px 4px 0px #00000040;
    padding: 16px;
}   


.p-mi-ingre{
    font-weight: bold;
}