.container-products-global{
    height: 100%;
    display: flex;
    justify-content: center;
}

.container-product{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    align-items: center;
    cursor: pointer;
}

.img-product{
    width: 302px;
    height: 350px;
}

.title-product{
    margin: 0;
    color: #1B1C3C;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.text-product{
    margin: 0;
    margin: 0;
    color: #1B1C3C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 1366px) {
    .container-products-global{
        justify-content: space-evenly;
    }

    .img-product{
        width: 252px;
        height: 292px;
    }

    .title-product{
        font-size: 22px;
    }

    .text-product{
        font-size: 14px;
        text-align: center;
    }
}

/* version petit ecran */
@media (max-width: 1024px) {
    
}
